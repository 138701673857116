import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    // primary: {
    //   // Purple and green play nicely together.
    //   main: purple[500],
    // },
    secondary: {
      // This is green.A700 as hex.
      main: '#B0BEC5',
    },
  },
});
export default function ConfirmDialog(props) {
  const [ open, setOpen ] = React.useState(true);
  const [ enableConfirm, setEnableConfirm ] = useState(props.enableConfirm);

  useEffect(() => {
    if (enableConfirm !== props.enableConfirm) {
      setEnableConfirm(props.enableConfirm)
    }
  })
  const handleClose = () => {
    if (props.onCancel) {
      props.onCancel(props)
    }
    setOpen(false);

  };

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm(props)
    }
    setOpen(false);

  };

  const handleContinue = () => {

  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 'fit-content' } }}
            maxWidth="xs"
            open={open}
            >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
              {props.content || 'Are you sure ?'}
              <br/>
              {props.contentConfirm || ''}
            </DialogContent>

            <DialogActions >
            {!props.hideCancel &&  <Button autoFocus
                onClick={handleClose}
                variant={props.cancelBtnVariant || 'contained'} color={props.cancelBtnColor || 'secondary'} sx={{height: '42px'}}>
                {props.cancelBtnText || 'Cancel'}
              </Button>}
              <Button
                onClick={handleConfirm}
                disabled={!enableConfirm}
                startIcon={props.confirmBtnStartIcon}
                variant={props.confirmBtnVariant || 'contained'}
                color={props.confirmBtnColor || 'primary'} sx={{height: '42px'}}>
                  {props.confirmBtnText || 'Confirm'}

              </Button>

            </DialogActions>
        </Dialog>
      </Box>
     </ThemeProvider>
  );
}
