import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import {
  useGridApiRef,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import { randomStatusOptions, randomPrice } from '@mui/x-data-grid-generator';

import AddCardIcon from '@mui/icons-material/AddCard';
import SettingsIcon from '@mui/icons-material/Settings';


export default function GiftCardOverview(props) {
  const [ open, setOpen ] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const clickMenuItem = (onClick) => (event) => {
    setOpen(false);
    onClick()
  }
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const apiRef = useGridApiRef();

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (value) => currencyFormatter.format(value.value),
    cellClassName: 'font-tabular-nums',
  };

  function WhatCell(props) {
    const capitalized  = props.value.charAt(0).toUpperCase() + props.value.slice(1)
    let comp = <>{capitalized}</>
    switch (props.value) {

      case 'boost': comp =  comp = <>{props.value}<AddCardIcon/>

      </>
      break;
      case 'standard': comp = <>{props.value}</>
      break;
    }

    return comp
    }
  const handleSettingsClick = (id) => () => {
    const row = rows.filter((r) => r.id === id)[0]
    props.actions.boost.onClick()
    console.log('CLICKED ', row)
  };
  const columns = [
    { field: 'what', headerName: 'What', width: 100, editable: false, renderCell: (params) => <WhatCell {...params} />  },
    { field: 'queued', headerName: 'Queued', width: 100, editable: false, type: 'number' },
    { field: 'price', headerName: 'Value', ...usdPrice },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {

        return [
          <GridActionsCellItem

           icon={id === 2 ? <Button sx={{height: '40px'}}
            ref={anchorRef}
            onClick={handleToggle}
            startIcon={<SettingsIcon/>}>
              </Button> : <></>}
           label="Settings"
           sx={{
             color: 'primary.main',
           }}
           onClick={handleSettingsClick(id)}
         />

        ];
      },
    },
  ]

  const rows = [
    { id: 1, what: 'standard', queued: 44, price: randomPrice(), subTotal: randomPrice() },
    { id: 2, what: 'boost', queued: 143, price: 755.00, subTotal: randomPrice() },

  ]

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h6" sx={{ fontSize: 20, textAlign: 'center', fontWeight: 500 }} color="text.secondary" gutterBottom>
          Gift Card Overview
        </Typography>
        <Typography variant="h5" component="div" sx={{ fontSize: 12, textAlign: 'center', color: '#9e9e9e', fontWeight: 500 }}>
          Last Checked: June 21st, 1:14PM
        </Typography>
        <DataGrid

          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{ backgroundColor: 'white', borderColor: 'transparent'
          }}
          disableColumnFilter
          hideFooterRowCount={true}
          hideFooterSelectedRowCount
          hideFooterPagination
        />

      </CardContent>
      <CardActions>
      </CardActions>
    </React.Fragment>
  );
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
