import React, { useContext } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { OnboardingContext } from "./OnboardingContext";

const FeedVersionDropdown = () => {
  const onboarding = useContext(OnboardingContext);

  if (!onboarding.allow_set_feed_version) {
    return null;
  }

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <InputLabel>Feed Version</InputLabel>
        <Select
          value={onboarding.feed_version}
          label="Feed version"
          disabled={!onboarding.enable_set_feed_version}
          onChange={() => {
            // TODO
          }}
        >
          {onboarding.feed_version_options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FeedVersionDropdown;
