import programPlaceholder from '../../assets/programs/ProgramPlaceholder.svg';
import play4 from '../../assets/programs/Play4.svg';
import rewardedPlay from '../../assets/programs/RewardedPlay.svg';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ScheduleIcon from '@mui/icons-material/Schedule';


export function isNumeric(n) {
 return !isNaN(parseFloat(n)) && isFinite(n);
}

export function monthsMap(month)  {
    const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'}
    return months[month]
  }

export function formatDate(newDate) {

  const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const d = newDate
  const year = d.getFullYear()
  const date = d.getDate()
  const monthIndex = d.getMonth()
  const monthName = months[d.getMonth()]
  const dayName = days[d.getDay()] // Thu
  let formatted = null

  formatted = `${monthName} ${d.getDate()}th, ${year}`


  return formatted.toString()
}

export function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
}

export function daysDiff(date1, date2) {

  let difference = date2.getTime() - date1.getTime();
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  if (totalDays > 0) {
    return date1.getDate() !== date2.getDate()
  } else {
    return false
  }
}

export function getAdminDomain(env) {
  if (env === 'production') {
    return 'https://admin.louderrewards.com'
  }
  if (env === 'staging') {
    return 'https://admin.influencestaging.com'
  }
  return 'http://localhost:3000'
}


export function getProgramImage(program) {
  if (!program || !program.name) {
    return programPlaceholder
  }
  if (program.name.toLowerCase() === 'play4') {
    return play4
  }
  if (program.name.toLowerCase() === 'rewarded play') {
    return rewardedPlay
  }
  return programPlaceholder
}


export function getPlayerPhoneObject(player) {
  const currentPhone = player.phoneNumbers ? player.phoneNumbers.filter((phone) => phone.current === true)[0] : null

  let phone = { field: 'phoneNumber',
                type: 'search',
                value: player.phoneNumber,
                label: currentPhone ? currentPhone.number : 'Not Provided' }


  const status = player.phoneVerificationStatus
  switch (status) {
    case 'pending_carrier_lookup':
          phone['tooltip'] = 'Identifying carrier'
          phone['icon'] = <ReportProblemIcon fontSize="small" color="warning" />
          break;
    case 'no_phone_number':
          phone['tooltip'] = 'Not Provided'
          phone['icon'] = <PhoneIcon fontSize="small" />
          break;
    case 'failed_verification':
          phone['tooltip'] = 'Verification Failed'
          phone['icon'] = <ReportProblemIcon fontSize="small" color="warning" />
          break;
    case 'disabled_too_many_attempts':
          phone['tooltip'] = 'Too Many Attempts'
          phone['icon'] = <FiberManualRecordIcon fontSize="small" color="error" />
          break;
    case 'duplicate_phone_number':
          phone['tooltip'] = 'Duplicated Phone number'
          phone['icon'] = <FiberManualRecordIcon fontSize="small" color="error" />
          break;
    case 'verified':
          phone['tooltip'] = 'Verified'
          phone['icon'] = <CheckBoxIcon fontSize="small" color="success"/>
          break;
    case 'invalid_number':
          phone['tooltip'] = 'Invalid Number'
          phone['icon'] = <ReportProblemIcon fontSize="small" color="warning" />
          break;
    case 'unverified_phone_number':
          phone['tooltip'] = 'Unverified Number'
          phone['icon'] = <ScheduleIcon fontSize="small" color="diabled" />
          break;
    case 'invalid_carrier':
          phone['tooltip'] = 'Invalid Carrier'
          phone['icon'] = <ReportProblemIcon fontSize="small" color="warning" />
          break;
  }

  return phone

}
