import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BoostCAP({onFieldChange, boostCap}) {
  const [ val, setVal ] = useState(boostCap)

  const onChangeNumberField = (newValue) => {
    let value = newValue ? Number(newValue.target.value) : 0
    if (isNaN(value) || value < 0 || value === 0) {
      value = 1
    }
    if (value > 30) {
      value = 30
    }
    setVal(value)
    onFieldChange({ cap: value })

  }

  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Define how many Boost Redemptions a player can get in a given timeframe.
      </Typography>
      <Box sx={{ background: "#e3f2fd",
                height: '90px',
                display: 'flex',
                alignItems: 'start',
                }}>
                <Box sx={{marginTop: '15px'}}>
                <ErrorOutlineIcon sx={{margin: '8px', color: "#0288D1"}}/>
                </Box>
                <Box>
                  <Typography sx={{fontSize: '14px', fontWeight: 400, margin: 2, display: 'flex'
                    }} >
                      <Box sx={{height: '20px', display: 'flex'}}>

                        Once the cap is reached the redemption will be placed for human review.
                      </Box>
                   </Typography>
                </Box>
      </Box>
      <Box>
      <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
         <Input
           id="standard-adornment-cap"
           endAdornment={<InputAdornment position="end">cards</InputAdornment>}
           aria-describedby="standard-weight-helper-text"
           inputProps={{
             'aria-label': 'cap',
           }}
           value={val}
           onChange={onChangeNumberField}
         />
         <FormHelperText id="standard-weight-helper-text">Earned in the last 30 days</FormHelperText>
       </FormControl>
      </Box>
    </Stack>
  )

}

export default function BoostRedmptionSettingActionContent({ actions, onFieldChange, boostCap }) {
  const [ tabView, setTabView ] = useState(1)


  const handleChangeTab = (event, newValue) => {
    setTabView(newValue);
  };

  return (
    <Box sx={{ }}>
      <Box sx={{ width: '100%' }}>
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="boost-red-dialog">
           <Tab label="CARDS OF POINTS" {...a11yProps(0)} />
           <Tab label="CAP" {...a11yProps(1)} />
           <Tab label="ALERTS" {...a11yProps(2)} />
         </Tabs>
       </Box>
       <TabPanel tabView={tabView} index={0}>
         <Box sx={{margin: 1
         }}>TBD
        </Box>
       </TabPanel>

       <TabPanel tabView={tabView} index={1}>
         <BoostCAP onFieldChange={onFieldChange} boostCap={boostCap}/>
       </TabPanel>

       <TabPanel tabView={tabView} index={2}>
         TBD
       </TabPanel>

     </Box>
     <FormControlLabel disabled control={<Checkbox defaultChecked />} label="I confirm I want to switch the reward method." />

    </Box>
  )

}
