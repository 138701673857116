import React, { useContext, useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import ErrorMessage from "./ErrorMessage";
import Loading from "./Loading";
import OnboardingStepper from "./OnboardingStepper";
import OnboardingStatus from "./OnboardingStatus";
import OnboardingContent from "./OnboardingContent";

import { PlayerContext } from "../../PlayerContext";
import { OnboardingContext } from "./OnboardingContext";

import { getOnboarding } from "../../../../api/Onboarding";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    pending: {
      main: grey[500],
      light: grey[300],
      dark: grey[700],
      contrastText: "#fff",
    },
  },
});

const OnboardingContainer = () => {
  const player = useContext(PlayerContext);

  const [onboarding, setOnboarding] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getOnboarding(player.id).then(setOnboarding).catch(setError);
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!onboarding) {
    return <Loading />;
  }

  return (
    <OnboardingContext.Provider value={onboarding}>
      <ThemeProvider theme={theme}>
        <Stack spacing={2}>
          <OnboardingStepper />

          <Card>
            <CardContent>
              <OnboardingStatus />
            </CardContent>

            <Divider />

            <CardContent>
              <OnboardingContent />
            </CardContent>
          </Card>
        </Stack>
      </ThemeProvider>
    </OnboardingContext.Provider>
  );
};

export default OnboardingContainer;
