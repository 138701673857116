import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { addNote, getPlayerNotes } from '../../models/Player/Notes'
import ConfirmPlayerDialogsContents from './Common/ConfirmPlayerDialogsContents'
import { getLRPlayerProfile } from '../../models/Player/Profile'
import { PatternFormat } from 'react-number-format';
import Stack from '@mui/material/Stack';

import SideContainer from './SideComponents/SideContainer'
import PlayerTabs from './MainPage/PlayerTabs'
import ZendeskContainer from './MainPage/Zendesk/ZendeskContainer'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';
import ConfirmDialog from '../common/ConfirmDialog'
import HomeContainer from './MainPage/Home/HomeContainer'
import OnboardingContainer from './MainPage/Onboarding/OnboardingContainer'
import SpotlightContainer from "./MainPage/AppsOffers/Spotlight/SpotlightContainer";
import NotesSideContainer from './SideNotes/NotesSideContainer';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { TransitionGroup } from 'react-transition-group';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';


import NotesDial from './SideNotes/NotesDial'

import { PlayerContext } from './PlayerContext';
import { OnboardingContext } from './MainPage/Onboarding/OnboardingContext';

import { getOnboardingStatus } from '../../api/Onboarding';

export default function Player(props) {

  const [ player, setPlayer ] = useState(null)
  const [ onboarding, setOnboarding ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ mount, setMount ] = useState(false)
  const [ loadingMessage, setLoadingMessage ] = useState('')
  const [ alert, setAlert ] = useState(null)
  // const [ zendeskResponse, setZendeskResponse ] = useState(null)
  const [ lrPlayer, setLRPlayer ] = useState(null)
  const [ notes, setNotes ] = useState(null)
  const [ showPlayerNotes, setShowPlayerNotes ] = useState(false)
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ staticAlert, setStaticAlert ] = useState(null)
  const [ forceLoad, setForceLoad ] = useState(false)
  const [ refreshNotes, setRefreshNotes ] = useState(false)
  const [ addedNewNote, setAddedNewNote ] = useState(null)
  const [ addedTicket, setAddedTicket ] = useState(null)
  const [ enableDialog, setEnableDialog ] = useState(true)
  const [ noteChar, setNoteChar ] = useState(0)
  const [ permittedActions, setPermittedActions ] = useState(props.currentUser ? props.currentUser.permittedPlayerActions : [])
  const containerRef = React.useRef(null);

  const playerTabs = [
    "Home",
    "Points",
    "Devices",
    "Onboarding",
    "Apps & Offers",
    "Activity Groups",
    "Rewards",
    "Fraud",
    "More Sections",
  ];

  // const setupZendeskPlayerTickets = async () => {
  //   const response = await playerZendeskTickets({playerId: player.id})
  //   console.log('RESPONSE ', response)
  //   setZendeskResponse(response)
  // }
  const getNotes = async (player) => {
    const playerNotes = await getPlayerNotes({playerId: player.id})
    setNotes(playerNotes.notes)
  }

  const resetNotes = (notes) => {
    setNotes(notes)
    setRefreshNotes(refreshNotes)
  }
  const lrPlayerSetup = async (player) => {
    const response = await getLRPlayerProfile({playerId: player.id})

    if (response && response.player) {
      setLRPlayer(response.player)
      setNotes(response.player.notes)
      setShowPlayerNotes(true)

    } else {
      setStaticAlert({ severity: 'error', message: "READ ONLY STATE ! There seems to be isues connecting to lr-admin API" })
    }

  }
  const getPlayer = async () => {
    setLoading(true)
    if (props.player) {
      setPlayer(props.player)

      lrPlayerSetup(props.player)

      getOnboardingStatus(props.player.id).then(status => setOnboarding({ status }))
    }
    setLoading(false)
  }
  useEffect(() => {
    if (!player && props.player) {
      getPlayer()
    }
    if (props.forceLoad !== forceLoad) {
      setForceLoad(props.forceLoad)
      setLoadingMessage(props.forceLoadMessage)
    }

    const validTicket = !addedTicket || addedTicket.toString().trim().length === 0 || addedTicket.toString().trim().length === 6 || addedTicket.toString().trim().length === 7

    const validNote = addedNewNote && addedNewNote.toString().trim().length > 0
    if (enableDialog !== (validTicket && validNote)) {
      setEnableDialog(validTicket && validNote)
    }



  })
  const onConfirmADialog =  async () => {
    // console.log('CONFIRM DIALOG ', dialogProps)
    if (dialogProps && Object.keys(dialogProps).length > 0) {
      const dialogType = dialogProps.type
      setLoading(true)
      let response = null
      switch (dialogType) {
        case 'New Note':
          setLoadingMessage('Adding Note')
          response = await addNote({ playerId: player.id, note: addedNewNote, zendeskTicketNumber: addedTicket, admin: props.user })
          if (response.status === 200) {
            resetNotes(response.notes)
          }
          onDialogCancel()
          break;

        default:
          console.log("un supported dialog type");
      }

      if (response) {

        const severity = response.status === 200 ? 'success' : 'error'
        const message = response.status === 200 ? `${dialogType} Success!` : `${dialogType} Failure!`

        setAlert({ display: true, severity, message })

      }
    }

    setDialogProps(null)
    setLoading(false)
    setLoadingMessage('')

  }

  const onDialogCancel = () => {
    setAddedNewNote(null)
    setAddedTicket(null)
    setDialogProps(null)
  }

  const onAddNote = () => {

    const onAddedNote = (type) => (event) => {
      const value = type === 'note' ? event.target.value : event.floatValue

      const timer = setTimeout(() => {
        if (type === 'note') {
          setNoteChar(value.length)
          setAddedNewNote(value)
        } else {
          setAddedTicket(value)
        }

      }, 100);
      return () => clearTimeout(timer);

    }



    const content = <ConfirmPlayerDialogsContents dialog="newNote" setAddedNewNote={setAddedNewNote} setAddedTicket={setAddedTicket} addedTicket={addedTicket}/>

    setDialogProps(
      { content,
        title: 'Add A New Note',
        type: 'New Note',
        contentConfirm: '',
        enableConfirm: false,
        confirmBtnText: 'Add',
       })
      setEnableDialog(false)

  }



  const tabsComp = {
    'Home': <HomeContainer player={player} lrPlayer={lrPlayer} />,
    'Onboarding': <OnboardingContainer />,
    'Apps & Offers': <SpotlightContainer style={{display: 'inline-block'}}/>
  }

  const onCloseAlert = () => {
    setAlert(null)
  }

  const onDial = (name) => {
    if (name === 'notes') {
      setShowPlayerNotes(true)
    }
  }
  const onMinize = () => {
    setShowPlayerNotes(false)
  }

  return (

    <PlayerContext.Provider value={player}>

    <Box sx={{ display: 'flex', height: '90%', }} >
    { !player || forceLoad
      ?  <IMLoader message={loadingMessage || 'Loading Player...'}/>
      : <> {!showPlayerNotes && lrPlayer && <NotesDial onDial={onDial}/>}
      {
        staticAlert && <Box sx={{
          display: 'flex',
          width: '-webkit-fill-available',
          position: 'absolute',
          justifyContent: 'center',
          top: '60px'
        }}
        ><Alert
          severity={staticAlert.severity}
          >{staticAlert.message}</Alert></Box>
      }
      {alert && alert.display &&
        <IMAlerts
          onClose={onCloseAlert}
          severity={alert.severity}
          message={alert.message}/>}
      {dialogProps && <ConfirmDialog
          onCancel={onDialogCancel}
          contentConfirm={dialogProps.contentConfirm}
          {...dialogProps}
          onConfirm={onConfirmADialog}
          enableConfirm={enableDialog}
          />}
      {player && player.id &&
        <SideContainer

          env={props.env}
          player={player}
          forceLoad={setForceLoad}
          setLoadingMessage={setLoadingMessage}
          disableEditing={staticAlert}
          resetNotes={resetNotes}
          permittedActions={permittedActions}
          searchHeaderFilterButtonClicked={props.searchHeaderFilterButtonClicked}
          lrPlayer={lrPlayer}/>}

      <Box
        sx={{ position: 'fixed',
              paddingLeft: '300px',
              height: '-webkit-fill-available',
              overflowY: 'scroll',
              width: '100%',
              top: '90px',
              '&::-webkit-scrollbar': {
                  display: 'none',
                  width: 0,
                  height: 0,
                  '-ms-overflow-style': 'none',  /* IE and Edge */
                  'scrollbar-width': 'none'
              }
            }}

      >


            <Slide
              in={showPlayerNotes}
              direction="up"
              style={{ transformOrigin: '0 0 0' }}
              timeout={1000}
              {...(showPlayerNotes ? { timeout: 1000 } : {timeout: 800})}
              key={'notesCollapsed'}
              mountOnEnter unmountOnExit

             >
              <div><NotesSideContainer
                ref={containerRef}
                player={player}
                onAddNote={onAddNote}
                onMinize={onMinize}
                refreshNotes={refreshNotes}
                notes={notes}/>
            </div></Slide>

      {player && (
        <OnboardingContext.Provider value={onboarding}>
          <PlayerTabs
            tabs={playerTabs}
            tabsComp={tabsComp}
            lrPlayer={lrPlayer}
            player={player}
          />
        </OnboardingContext.Provider>
      )}

      </Box>
      </>}
    </Box>

    </PlayerContext.Provider>
  )

}
